<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <van-sticky>
        <van-nav-bar
          :title="canEdit ? '完善定制需求' : '查看定制需求'"
          left-arrow
          @click-left="$router.back()"
        />
      </van-sticky>
      <van-collapse v-model="activeNames">
        <van-collapse-item title="定制酒订单信息及说明" name="1">
          <van-row>
            <van-col span="6"><strong>订单编号：</strong></van-col>
            <van-col span="18"
              >{{ info.orderId }}
              <van-tag
                v-if="
                  info.flowStatus == 0 &&
                  info.quantity > 1 &&
                  info.orderId &&
                  info.orderId[0] == 'D' &&
                  info.schemeNo == 0 &&
                  canEdit
                "
                type="primary"
                @click="tosplit()"
                >拆分定制</van-tag
              ></van-col
            >
          </van-row>
          <van-row>
            <van-col span="6"><strong>商品名称：</strong></van-col>
            <van-col span="18">{{ info.productName }}</van-col>
          </van-row>
          <van-row>
            <van-col span="6"><strong>规格数量：</strong></van-col>
            <van-col span="18"
              >{{ info.skuDesc }}<strong> x{{ info.quantity }}</strong></van-col
            >
          </van-row>
          <van-row>
            <van-col span="6"><strong>A3客户：</strong></van-col>
            <van-col span="18">{{ info.a3CustomerId }}</van-col>
          </van-row>
          <span style="color: red"
            >请大家规范填写确认后的准确信息，字数尽量按建议填写（字数过多，影响字体大小和换行排版，导致反复修改设计图，定制打印效果差）</span
          >
        </van-collapse-item>
      </van-collapse>
      <van-form @submit="onSubmit">
        <!-- <van-field
          v-model="info.orderId"
          label="订单编号"
          placeholder="请输入中酿名酒惠订单编号"
          required
          :rules="[{ required: true, message: '请填写订单编号' }]"
        /> -->
        <van-field
          label="方案编号"
          :value="info.schemeNo"
          readonly
          v-if="info.schemeNo > 0"
        />
        <van-field label="定制场景" :readonly="!canEdit">
          <template #input>
            <van-radio-group v-model="info.itemType" direction="horizontal">
              <van-radio name="0" :disabled="!canEdit">个人定制</van-radio>
              <van-radio name="1" :disabled="!canEdit">企业定制</van-radio>
              <van-radio name="2" :disabled="!canEdit">职称定制</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-if="info.itemType == 0 || info.itemType == 2"
          v-model="info.name"
          label="定制姓名"
          placeholder="建议填写4个字以内的姓名"
          required
          :rules="[{ required: true }]"
          maxlength="8"
          show-word-limit
          :readonly="!canEdit"
        />
        <van-field
          v-if="info.itemType == 0"
          v-model="info.motto"
          rows="2"
          autosize
          label="个人座右铭"
          type="textarea"
          maxlength="16"
          placeholder="建议填写12个字以内的座右铭或人生格言"
          show-word-limit
          required
          :rules="[{ required: true }]"
          :readonly="!canEdit"
        />
        <van-field
          v-if="info.itemType == 2"
          v-model="info.jobTitle"
          label="个人职称"
          placeholder="建议填写4个字以内的个人职称"
          maxlength="6"
          show-word-limit
          required
          :rules="[{ required: true }]"
          :readonly="!canEdit"
        />
        <van-field
          v-if="info.itemType == 1 || info.itemType == 2"
          v-model="info.company"
          rows="1"
          autosize
          label="企业名称"
          type="textarea"
          placeholder="建议填写12个字以内的企业名称或简称"
          maxlength="20"
          show-word-limit
          :required="info.itemType == 1"
          :rules="[{ required: info.itemType == 1 }]"
          :readonly="!canEdit"
        />
        <van-field
          v-if="info.itemType == 1 || info.itemType == 2"
          v-model="info.companyCulture"
          rows="2"
          autosize
          label="企业文化"
          type="textarea"
          placeholder="建议填写12个字以内的企业文化（没有的需上传企业LOGO）"
          maxlength="16"
          show-word-limit
          :readonly="!canEdit"
        />
        <van-cell-group>
          <van-cell :title="companyLogoTitle">
            <template #label>
              <van-uploader
                v-model="fileList"
                :after-read="afterRead"
                :max-count="1"
                @delete="info.companyLogo = ''"
                :disabled="!canEdit"
              />
              矢量文件或不小于1MB以上高清图片
            </template>
          </van-cell>
        </van-cell-group>
        <van-field
          v-model="info.remark"
          rows="2"
          autosize
          label="备注"
          type="textarea"
          maxlength="256"
          placeholder="想对设计师说点什么呢"
          show-word-limit
          :readonly="!canEdit"
        />
        <van-button
          round
          block
          type="info"
          native-type="submit"
          style="margin-top: 20px"
          v-if="canEdit"
          >提交</van-button
        >
      </van-form>
    </div>

    <van-popup v-model="splitShow" closeable :style="{ width: '96%' }">
      <div style="padding: 20px; text-align: right; line-height: 30px">
        <van-row>
          <strong style="float: left">请先设置拆分为 </strong
          ><van-stepper
            v-model="splitCount"
            min="2"
            max="2"
            disable-input
            style="float: left"
            @change="splitCountChange"
          /><strong style="float: left"> 个定制方案</strong>
        </van-row>
        <div
          style="
            border: 1px solid gray;
            padding: 5px;
            font-size: 12px;
            text-align: center;
          "
        >
          <van-row>
            <van-col span="14">方案编号</van-col>
            <van-col span="10"
              >下单数量 <strong>{{ info.quantity }}</strong> 拆分为</van-col
            >
          </van-row>
          <van-row
            v-for="(quantity, index) in splitResult"
            :key="index"
            style="border-top: 1px solid gray"
          >
            <van-col span="14">{{
              splitResult.length * 100 + index + 1
            }}</van-col>
            <van-col span="10"
              ><van-stepper
                v-model="splitResult[index]"
                style="display: inline"
            /></van-col>
          </van-row>
        </div>
        <van-button type="danger" size="mini" @click="savesplit"
          >确定拆分</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import { addOrEdit, split } from "@/api/customizedWineNeed";
import { uploadCustomizedWineNeedImage } from "@/api/file";
import { getPageList } from "@/api/customizedWineNeed";

const defaultInfo = {
  itemType: "0",
  name: "",
  motto: "",
  jobTitle: "",
  company: "",
  companyCulture: "",
  companyLogo: "",
};
export default {
  data() {
    return {
      loading: true,
      info: Object.assign({}, defaultInfo),
      fileList: [],
      activeNames: ["1"],

      splitShow: false,
      splitCount: 2,
      splitResult: [1, 1],
    };
  },
  components: {},
  created() {
    this.onLoad();
  },
  watch: {},
  mounted() {},
  computed: {
    canEdit() {
      return (
        (this.info.flowStatus == 0 || this.info.flowStatus == 14) &&
        this.info.creatorId == this.$store.state.user.id
      );
    },
    companyLogoTitle(){
      return this.info.itemType==0?'LOGO':'企业LOGO（没有的需填写企业文化）';
    },
  },
  methods: {
    async onLoad() {
      let id = this.$route.query.id;
      if (id) {
        let d = await getPageList({
          current: 1,
          pageSize: 10,
          id: id,
        });
        if (!d.data.success && d.data.errorMessage) {
          Toast(d.data.errorMessage);
          return;
        }

        this.info = d.data.data[0];
        this.info.itemType = this.info.itemType.toString();
      }

      this.loading = false;
    },

    async onSubmit() {
      if (this.info.orderId.length <= 0 || this.info.orderId.length > 32) {
        Toast("订单编号不能为空或超过32个字");
        return;
      }
      if (this.info.itemType == 0) {
        //个人定制
        if (this.info.name.length > 32) {
          Toast("定制姓名不能超过32个字");
          return;
        }
        if (this.info.motto.length > 512) {
          Toast("个人座右铭不能超过512个字");
          return;
        }
      } else if (this.info.itemType == 1) {
        //企业定制
        if (!this.info.companyCulture && !this.info.companyLogo) {
          Toast("请填写企业文化或上传企业LOGO");
          return;
        }
        if (this.info.company.length > 128) {
          Toast("企业名称不能超过128个字");
          return;
        }
        if (this.info.companyCulture.length > 512) {
          Toast("企业文化不能超过512个字");
          return;
        }
      } else if (this.info.itemType == 2) {
        //职称定制
        if (!this.info.companyCulture && !this.info.companyLogo) {
          Toast("请填写企业文化或上传企业LOGO");
          return;
        }
        if (this.info.name.length > 32) {
          Toast("定制姓名不能超过32个字");
          return;
        }
        if (this.info.jobTitle.length > 32) {
          Toast("个人职称不能超过32个字");
          return;
        }
        if (this.info.company.length > 128) {
          Toast("企业名称不能超过128个字");
          return;
        }
        if (this.info.companyCulture.length > 512) {
          Toast("企业文化不能超过512个字");
          return;
        }
      }
      Dialog.confirm({
        title: "提交后无法修改和删除，请先核对填写信息无误，确认现在提交？",
      }).then(async () => {
        const d = await addOrEdit(this.info);
        if (d.data.success) {
          Toast(
            this.info.id
              ? "你已成功更新百年糊涂私人定制申请，请等待部门负责人审核。"
              : "你已成功提交百年糊涂私人定制申请，请等待部门负责人审核。"
          );
          if(d.data.data){
          Dialog.confirm({
            title: '当前订单下还有其他产品未完善定制信息，是否现在去完善？',
          })
            .then(() => {
              this.$router.push({
                path: "/customizedwineneed/add",
                query: { id: d.data.data },
              });
            })
            .catch(() => {
              this.info = Object.assign({}, defaultInfo);
              this.fileList = [];
              this.$router.push("/customizedwineneed");
            });
          }else{
              this.info = Object.assign({}, defaultInfo);
              this.fileList = [];
              this.$router.push("/customizedwineneed");
          }
        } else {
          Toast(d.data.errorMessage);
        }
      });
    },

    async afterRead(file) {
      if (file.file.type == "image/png" && file.file.size < 1000 * 1024) {
        file.status = "failed";
        file.message = "上传失败";
        Toast("请上传矢量文件或不小于1MB以上高清图片");
        return;
      }

      Toast("文件上传中");
      let formData = new FormData();
      formData.append("file", file.file);
      let aresult = await uploadCustomizedWineNeedImage(formData);

      let url = aresult.data; // Get url from response
      this.info.companyLogo = url;

      this.fileList = [];
      this.fileList.push({
        url: url,

        imageFit: "contain",
        previewSize: 80,
      });
      Toast("文件上传成功");
    },

    tosplit() {
      this.splitShow = true;
    },
    splitCountChange(val) {
      this.splitResult = [];
      for (let index = 1; index <= val; index++) {
        this.splitResult.push(1);
      }
    },
    savesplit() {
      if (this.splitResult.length > this.info.quantity) {
        Toast("拆分定制方案的数量不能大于下单数量，请修改");
        return;
      }
      if (
        this.info.quantity !=
        this.splitResult.reduce((total, val) => total + val, 0)
      ) {
        Toast(
          `拆分后的下单数量${this.splitResult}相加与原单数量${this.info.quantity}不相等，请修改`
        );
        return;
      }

      Dialog.confirm({
        title: "拆分定制方案后无法撤回，请先核对准确无误，确认现在拆分？",
      }).then(async () => {
        const d = await split({
          id: this.info.id,
          quantitys: this.splitResult,
        });
        if (d.data.success) {
          Toast("拆分定制操作成功");
          this.info.quantity = this.splitResult[0];
          this.info.schemeNo = this.splitResult.length * 100 + 1;
          this.splitShow = false;
        } else {
          Toast(d.data.errorMessage);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  /deep/.van-checkbox {
    margin-top: 5px;
  }
  /deep/ .btn-box {
    margin-top: 25px;
  }
}
</style>