import request from '@/utils/request'

export function getPageList(params) {
    return request({
      url: '/api/CustomizedWineNeed/MyPage',
      params: { ...params },
      method: 'get',
      header: {
        'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
      },
  
    })
  }

  export function addTrial (params) {
    return request({
      url: '/api/CustomizedWineNeed/CreateTrial',
      data: { ...params },
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
  }
  
export function addOrEdit (params) {
    return request({
      url: '/api/CustomizedWineNeed/CreateOrUpdate',
      data: { ...params },
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
  }

  export function updateFlow (params) {
    return request({
      url: '/api/CustomizedWineNeed/UpdateFlow',
      data: { ...params },
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
  }

  export function split (params) {
    return request({
      url: '/api/CustomizedWineNeed/Split',
      data: { ...params },
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
  }